import * as React from 'react';

import { cn } from '@/renderer/utils/cn';
import { randomSeed } from '@/renderer/utils/randomSeed';

interface CellBreakpoints {
  breakpoint?: 'sm' | 'xl' | '2xl';
}

const Table = React.forwardRef<HTMLTableElement, React.HTMLAttributes<HTMLTableElement>>(
  ({ className, ...props }, ref) => (
    <div className="relative flex w-full flex-row flex-nowrap">
      <table ref={ref} className={cn('w-full caption-bottom text-sm', className)} {...props} />
    </div>
  ),
);

Table.displayName = 'Table';

const TableHeader = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <thead ref={ref} className={cn('group/thead [&_tr]:border-b', className)} {...props} />
));

TableHeader.displayName = 'TableHeader';

const TableBody = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <tbody
    ref={ref}
    className={cn('group/tbody border-0 [&_tr:last-child]:border-0', className)}
    {...props}
  />
));

TableBody.displayName = 'TableBody';

const TableFooter = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <tfoot
    ref={ref}
    className={cn('bg-zinc-100 text-right dark:bg-zinc-800 [&_tr]:border-t', className)}
    {...props}
  />
));

TableFooter.displayName = 'TableFooter';

const TableRow = React.forwardRef<
  HTMLTableRowElement,
  React.HTMLAttributes<HTMLTableRowElement> & CellBreakpoints
>(({ className, breakpoint = '', ...props }, ref) => (
  <tr
    ref={ref}
    className={cn(
      'border-b border-zinc-900/5 transition-colors group-[&]/tbody:hover:bg-zinc-100 data-[state=selected]:bg-zinc-100 dark:border-white/10 group-[&]/tbody:dark:hover:bg-zinc-800 dark:data-[state=selected]:bg-gray-800',
      // breakpoint === 'sm'
      //   ? 'max-sm:flex max-sm:group-[&]/thead:flex-row max-sm:group-[&]/thead:flex-wrap'
      //   : breakpoint === 'xl'
      //     ? 'max-xl:flex max-xl:group-[&]/thead:flex-row max-xl:group-[&]/thead:flex-wrap'
      //     : breakpoint === '2xl'
      //       ? 'max-2xl:flex max-2xl:group-[&]/thead:flex-row max-2xl:group-[&]/thead:flex-wrap'
      //       : 'max-sm:flex max-sm:group-[&]/thead:flex-row max-sm:group-[&]/thead:flex-wrap',
      className,
    )}
    {...props}
  />
));

TableRow.displayName = 'TableRow';

const TableHead = React.forwardRef<
  HTMLTableCellElement,
  React.ThHTMLAttributes<HTMLTableCellElement> & CellBreakpoints
>(({ className, breakpoint, ...props }, ref) => (
  <th
    ref={ref}
    className={cn(
      'group/th whitespace-nowrap border-b border-zinc-900/10 bg-zinc-50 px-2 text-left align-middle font-medium text-zinc-500 dark:border-white/10 dark:bg-zinc-900 dark:text-zinc-300 [&:has([role=checkbox])]:pr-0',
      // breakpoint === 'sm'
      //   ? 'sm:min-h-12 max-sm:group-[&]/thead:py-2 sm:p-4'
      //   : breakpoint === 'xl'
      //     ? 'xl:min-h-12 max-xl:group-[&]/thead:py-2 xl:p-4'
      //     : breakpoint === '2xl'
      //       ? '2xl:min-h-12 max-2xl:group-[&]/thead:py-2 2xl:p-4'
      //       : 'min-h-12 p-4 group-[&]/thead:py-2',
      className,
    )}
    {...props}
  />
));

TableHead.displayName = 'TableHead';

const TableCell = React.forwardRef<
  HTMLTableCellElement,
  React.TdHTMLAttributes<HTMLTableCellElement> & CellBreakpoints
>(({ className, breakpoint = '', ...props }, ref) => (
  <td
    ref={ref}
    className={cn(
      'break-words px-4 align-middle before:-ml-2 before:block before:font-semibold before:content-[attr(title)] empty:hidden dark:text-zinc-400 [&:has([role=checkbox])]:pr-0',
      // breakpoint === 'sm'
      //   ? 'sm:py-4 sm:before:hidden sm:before:content-none sm:empty:table-cell'
      //   : breakpoint === 'xl'
      //     ? 'xl:py-4 xl:before:hidden xl:before:content-none xl:empty:table-cell'
      //     : breakpoint === '2xl'
      //       ? '2xl:py-4 2xl:before:hidden 2xl:before:content-none 2xl:empty:table-cell'
      //       : 'py-4 before:hidden before:content-none empty:table-cell',
      className,
    )}
    {...props}
  />
));

TableCell.displayName = 'TableCell';

const TableCaption = React.forwardRef<
  HTMLTableCaptionElement,
  React.HTMLAttributes<HTMLTableCaptionElement>
>(({ className, ...props }, ref) => (
  <caption
    ref={ref}
    className={cn(
      'mt-4 border-zinc-900/5 p-4 text-sm font-semibold text-zinc-500 dark:border-white/10 dark:text-zinc-400',
      className,
    )}
    {...props}
  />
));

TableCaption.displayName = 'TableCaption';

function TableSkeletonContent({
  className = undefined,
  rows = 1,
  breakpoint,
  widths,
}: {
  className?: string;
  rows?: number;
  widths: (number | string | [number, number])[];
} & CellBreakpoints) {
  return (
    <TableBody className={className}>
      {new Array(rows).fill(1).map((_, ridx) => (
        <TableRow key={ridx} breakpoint={breakpoint}>
          {widths.map((width, idx) => (
            <TableCell key={idx} breakpoint={breakpoint}>
              {typeof width === 'string' ? (
                <div className="inline-block h-4 animate-pulse select-none overflow-hidden whitespace-nowrap rounded bg-zinc-200 text-transparent dark:bg-zinc-700">
                  {width}
                </div>
              ) : (
                <div
                  className="inline-block h-4 w-[var(--shadowwidth)] animate-pulse rounded bg-zinc-200 dark:bg-zinc-700"
                  style={
                    {
                      '--shadowwidth': Array.isArray(width)
                        ? `${(
                            width[0] +
                            randomSeed(`${ridx}.${idx}:${width}`) * (width[1] - width[0])
                          ).toFixed(2)}ch`
                        : `${width.toFixed(2)}ch`,
                    } as React.CSSProperties
                  }
                />
              )}
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableBody>
  );
}

export {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
  TableSkeletonContent,
};
